.header-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.43125) 99.99%,
    rgba(0, 0, 0, 0) 100%
  );
}

.header-wrapper a {
  padding: 0px 50px;
  text-decoration: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #e6b17e;
}

.header-wrapper img,
.header-wrapper .header-links {
  padding: 12px 111px;
}
