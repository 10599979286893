.form-wrapper {
  background: #dfd4cd;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.form-wrapper form {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.form-wrapper h3 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #7c4e29;
}

.form-wrapper form input {
  margin: 9px 51px;
  padding-bottom: 12px;
  border: 0 solid #7c4e29;
  border-bottom-width: 1px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font: normal 16px / normal "Ubuntu";
  background-color: #dfd4cd;
  text-overflow: clip;
  width: 300px;
}

.form-wrapper form input::placeholder {
  color: #7c4e29;
}

.form-wrapper form input:focus {
  outline: none;
}

.form-wrapper button {
  width: 204px;
  padding: 12px 9px;
  margin: 50px 0px;
  border: none;
  background-color: #7c4e29;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 34px;
  color: #d9bca9;
}
